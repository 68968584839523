import React from "react";
import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";

export default function CustomCKEditor({ data, handleChange }) {
  const cloud = useCKEditorCloud({
    version: "44.1.0",
    premium: true,
  });

  if (cloud.status === "error") {
    return <div>Error!</div>;
  }

  if (cloud.status === "loading") {
    return <div>Loading...</div>;
  }

  const {
    ClassicEditor,
    Essentials,
    Paragraph,
    Bold,
    Italic,
    Image,
    ImageToolbar,
    ImageUpload,
    Base64UploadAdapter,
    ImageResize,
    ImageStyle,
  } = cloud.CKEditor;

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onChange={(event, editor) => handleChange(editor)}
      config={{
        licenseKey:
          "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3MzgyODE1OTksImp0aSI6ImFmZjZhZTQ1LTJiMWEtNDVlYS05OGU4LWJmMzc5ZjhjNzAyOSIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiLCJzaCJdLCJ3aGl0ZUxhYmVsIjp0cnVlLCJsaWNlbnNlVHlwZSI6InRyaWFsIiwiZmVhdHVyZXMiOlsiKiJdLCJ2YyI6ImQyMjUzZDZmIn0.t5-3DO-3zgH91m9ZT7oGEMI7mb6Gr4Qe_VbZcqZPZMKKZ_Ti_NDihL-vsAqTTyTCGZ152Myl_NZaFGcwHsT4Zg",
        plugins: [
          Essentials,
          Paragraph,
          Bold,
          Italic,
          Image,
          ImageToolbar,
          ImageUpload,
          ImageResize,
          ImageStyle,
          Base64UploadAdapter,
        ],
        toolbar: [
          "undo",
          "redo",
          "|",
          "bold",
          "italic",
          "|",
          "imageUpload",
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "resizeImage",
        ],
        image: {
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "resizeImage",
          ],
          styles: ["alignLeft", "alignCenter", "alignRight"],
          resizeOptions: [
            {
              name: "resizeImage:original",
              label: "Original size",
              value: null,
            },
            {
              name: "resizeImage:50",
              label: "50%",
              value: "50",
            },
            {
              name: "resizeImage:75",
              label: "75%",
              value: "75",
            },
          ],
        },
      }}
    />
  );
}
